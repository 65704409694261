import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const SolaVisMunicipality: UserMunicipality = {
  name: 'sola-vis',
  slogan: {value: '', id: 'banner.sola', meaning: 'bannerSlogan', description: 'slogan for sola'},
  data: {},
  bannerURL: 'https://ucarecdn.com/b9a629c9-98ce-4999-86df-18fffccf4ba3/Forside_Friskus_Sola.jpg',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: true,
  infoPageTitle: 'Fritidskort',
  infoSlots: [
   {
      type: InfoType.FRISKUS_LEISURE_ALL,
      title: 'Fritidskort',
      minAge: 6,
      maxAge: 16,
      amount: 2000,
      url: 'https://www.sola.kommune.no/solafritid/fritidskortet',
    },
    {
      type: InfoType.DYNAMIC,
      title: 'Støtteordninger',
      links: [
        {
          title: 'Tilskuddsordninger',
          url: 'https://www.sola.kommune.no/kultur-fritid-idrett-og-frivillighet/tilskudd/tilskudd/'
        },
        {
          title: 'Leie av utstyr',
          url: 'https://frilager.no/ords/f?p=120:1'
        },
        {
          title: 'Støttekontakt',
          url: 'https://www.sola.kommune.no/solafritid/tilrettelagt-fritid/fritidskontakttjenesten/vil-du-bli-fritidskontakt/'
        },
        {
          title: 'Frivillig innsats',
          url: 'https://sola.friskus.com/events?filters=global_filters_municipalities(EQ)13a715d2-4c7a-4f6b-9fe0-1adc4cc2bfd2$$true(AND)events-filtersquick-filter-volunteer(EQ)true'
        },
        {
          title: 'Ungdom',
          url: 'https://sola.friskus.com/events?filters=global_filters_municipalities(EQ)13a715d2-4c7a-4f6b-9fe0-1adc4cc2bfd2$$true(AND)events-filtersage-group(EQ)teenager$$false'
        },
        {
          title: 'Senior',
          url: 'https://sola.friskus.com/events?filters=global_filters_municipalities(EQ)13a715d2-4c7a-4f6b-9fe0-1adc4cc2bfd2$$true(AND)events-filtersage-group(EQ)pensioner$$false'
        },
        {
          title: 'Lån av lokale',
          url: 'https://www.sola.kommune.no/utleie-av-kommunale-lokaler/'
        },
        {
          title: 'Frivilligsentraler',
          url: 'https://sola.friskus.com/organisations/2c7ee030-6133-48bf-b44c-22b1097375d2'
        },
        {
          title: 'Frivilligsentraler',
          url: 'https://sola.frivilligsentral.no/'
        },
      ]
    },
  ]
}
